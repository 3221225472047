application.register("part_dialog_shipping", class extends Stimulus.Controller {
    get ref() {
        return {
            form: this.targets.find("form"),
        }
    }

    submitForm() {
        this.ref.form.dispatchEvent(new Event('submit', { 'bubbles': true, 'cancelable': true }));
    }

    connect() {

    }

});