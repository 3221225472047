application.register("comp_products_filter", class extends Stimulus.Controller {
    connect() {

    }
    doSort(event) {
        event.preventDefault();
        if(!event.currentTarget.classList.contains('state--active')) {
            event.currentTarget.closest(".elm_comp_nav").querySelector(".state--active").classList.remove("state--active");
            event.currentTarget.classList.add("state--active");

            application["getControllerForElementAndIdentifier"](document.querySelector(".part_filter"), "part_filter").doCatalogFilter(null,0,1);
        }
    }
    doFilter(event, type) {
        event.preventDefault();
        let elm = event.currentTarget;
        let page = 1;
        let pageFrom;
        let pagingType = 'default';
        let loadingButton;

        if(typeof elm.dataset.append !== "undefined") {
            pagingType = 'append'
            loadingButton = elm;
            loadingButton.classList.add("state--loading");
            loadingButton.setAttribute("disabled",true);
        }
        if(typeof elm.dataset.page !== "undefined") {
            page = elm.dataset.page;
        }
        if(typeof elm.dataset.pageFrom !== "undefined") {
            pageFrom = elm.dataset.pageFrom;
        }
        else {
            pageFrom = page;
        }

        application["getControllerForElementAndIdentifier"](document.querySelector(".part_filter"), "part_filter").doCatalogFilter(null,0,page,pageFrom,pagingType,null,function() {
            if(typeof loadingButton !== "undefined") {
                loadingButton.classList.remove('state--loading');
                loadingButton.removeAttribute('disabled');
            }
        });
    }
});