function importScript(url, callback, async) {
    if (typeof url === "string") {
        url = [url];
    }

    if (typeof async === "undefined") {
        async = false;
    }

    Promise.all(url.map((url) => {
        if (importScript.used.indexOf(url) === -1) {
            importScript.used.push(url);

            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.onload = resolve;
                script.onerror = reject;
                script.async = async;
                script.src = url;
                document.head.appendChild(script);
            });
        } else {
            return new Promise((resolve) => {
                let interval = setInterval(function () {
                    if (importScript.loaded.includes(url)) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 1);
            });
        }
    })).then(texts => {
        texts.forEach((data, index) => {
            if (typeof data !== "undefined") {
                importScript.loaded.push(url[index]);
                let script = document.querySelector(`script[src="${url[index]}"]`);

                if (script !== null) {
                    script.remove();
                }
            }
        });
    }).then(() => {
        if (callback) {
            callback();
        }
    });
}