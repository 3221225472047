loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {

        if(document.querySelector(".lib--dialog") !== null) {
            document.querySelector(".lib--dialog").addEventListener("mousedown", (e) => {
                if (e.target.classList.contains("lib--dialog")) {
                    document.documentElement.addEventListener("mouseup", function e() {
                        LibDialog.hide();
                        document.documentElement.removeEventListener("mouseup", e);
                    });
                }
            }, true);
        }
    }

    show(e) {
        let element = e.currentTarget;

        if (element === window) {
            element = document.querySelector(`[data-action="DOMContentLoaded@window->lib-dialog#show"]`);
        }

        let url = element.getAttribute("data-action-url");
        e.preventDefault();

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    connect() {
        LibTabs(this.element);
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options").replace(/\s/g,"").split(",");

        inView(element, () => {
            importScript([cdnjs.popper, cdnjs.tippy],()=> {
                importStyle(cdnjs.tippy_css);
                LibTippy(element, options);
            });
        });
    }
});

application.register("lib-slider", class extends Stimulus.Controller {
    connect() {
        inView(this.element, () => {
            LibSlider(this.element, JSON.parse(this.data.get("options")));
        });
    }
});

application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        LibGallery(this.element, this.data.get("id"));
    }
});

application.register("lib-slimselect", class extends Stimulus.Controller {
    connect() {
        let self = this;
        importStyle(cdnjs.slimselect_css);
        importScript([cdnjs.jquery, cdnjs.slimselect], function() {
            LibSlimselect(self.element);
        });
    }
});

document.addEventListener("submit", function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(`form.part_form_search`)) {
            (function(event) {
                let input = event.target.querySelector('[name="search"]');
                if(input !== null) {
                    if(!input.value.length) {
                        event.preventDefault();
                    }
                }
            }).call(target, e);
            break;
        }
    }
}, false);

document.addEventListener("click", function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(`[data-doc-click="basket-add"]`)) {
            (function(event) {
                document.body.insertAdjacentHTML("beforeend", '<div class="layout_summary_circle"></div>');
                let circles = document.querySelectorAll(".layout_summary_circle")
                let summary_circle =  circles[circles.length - 1];
                let basket;
                let left;

                if (document.getElementById("layout_header").classList.contains("headroom--not-top") && window.innerWidth > 960) {
                    basket = document.querySelector("#layout_header .wrp_header_nav .container");
                    left = basket.offsetLeft + basket.offsetWidth - 24 + "px";
                } else {
                    basket = document.querySelector("#layout_header .icon--basket");
                    left = basket.offsetLeft + 24 + "px";
                }

                summary_circle.style.top = event.clientY + "px";
                summary_circle.style.left = event.clientX + "px";

                setTimeout(()=>{
                    summary_circle.style.top = basket.offsetTop + 24 + "px";
                    summary_circle.style.left = left;
                    summary_circle.style.width = "1rem";
                    summary_circle.style.height = "1rem";
                    summary_circle.style.opacity = "0.1"

                    setTimeout(()=>{
                        summary_circle.parentElement.removeChild(summary_circle);
                    },800)
                },10);
            }).call(target, e);
            break;
        }
    }
}, false);
