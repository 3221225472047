let swup;
if (typeof Swup !== "undefined") {
    window.history.scrollRestoration = 'manual';

    swup = new Swup({
        containers: ['#layout_main', '#layout_nav_swup', '#layout_header'],
        cache: false,
        linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"]), a[href^="#"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"])'
    });

    swup.popState = false;
    swup.scrollValues = {};

    swup.on('clickLink', function () {
        document.body.classList.remove("body--no-scroll");

        if (document.querySelector(".drawer-active") !== null) {
            document.querySelector("#layout_nav").classList.remove("state--active");
            document.querySelector("#layout_nav").classList.add("is--opacity");
            document.querySelector("#layout_nav").style.setProperty('--drawerOpacity', `0`);
            document.documentElement.style["overflow"] = "";
            document.documentElement.classList.remove("drawer-active");
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.hide();
        }
    });

    document.addEventListener('swup:animationOutStart', () => {
        swup.scrollValues[window.location.href] = window.scrollY;
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);
    });

    document.addEventListener('swup:popState', (event,event2) => {
        swup.popState = true;
    });

    swup.on('contentReplaced', function () {
        const content = new DOMParser().parseFromString(swup.cache["getCurrentPage"]().originalContent, "text/html");

        [...content.head.querySelectorAll("[data-lib-swup-replace]")].forEach(element => {
            let tag = document.querySelector(`[data-lib-swup-replace=${element.dataset.libSwupReplace}]`);

            if (tag !== null) {
                tag.outerHTML = element.outerHTML
            } else {
                document.head.insertAdjacentHTML("beforeend", element.outerHTML);
            }
        });

        if (swup.scrollValues[window.location.href] !== "undefined" && swup.popState) {
            window.scrollTo(0, swup.scrollValues[window.location.href]);
            swup.popState = false;
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        LibAnchor.init();

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function(config){
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");

        if(typeof GAList !== 'undefined') {
            GAList = document.getElementById("layout_main").getAttribute("data-ga-list");
        }

        if(typeof reInitGAScripts !== 'undefined' && reInitGAScripts) {
            reInitGAScripts()
        }

    });
}