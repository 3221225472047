application.register("part_product_detail", class extends Stimulus.Controller {
    get ref() {
        return {
            select: this.targets.find("select"),
            count: this.targets.find("count"),
            price: this.targets.find("price"),
            priceOld: this.targets.find("priceOld"),
            priceVat: this.targets.find("priceVat"),
            priceOldBlock: this.targets.find("priceOldBlock"),
            basketButton: this.targets.find("basketButton"),
        }
    }

    changeVariant(e) {
        e.preventDefault();

        let element = e.currentTarget;
        let url = e.currentTarget.getAttribute('data-href');

        if (!url || !url.length) {
            url = e.currentTarget.getAttribute('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        importScript(cdnjs.jquery, () => {

            let data = {};

            let elm = $(element);
            if(elm.is('[data-change-input]')) {
                data[elm.attr('data-change-input')] = elm.val();
            }
            data.value = elm.val();

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: data
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
            });
        })
    }

    changeSelectVariant(e) {
        e.preventDefault();

        let element = e.currentTarget;
        let url = e.currentTarget.getAttribute('data-select-href');

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        importScript(cdnjs.jquery, () => {

            let data = {};

            let elm = $(element);
            if(elm.is('[data-change-input]')) {
                data[elm.attr('data-change-input')] = elm.val();
            }
            data.value = elm.val();

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: data
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
            });
        })
    }

    updateCount() {
        let count = parseInt(this.ref.count.value);
        let options = JSON.parse(this.element.dataset.options);
        let currencyRound = parseInt(options.currencyRound);

        let price = parseFloat(this.element.dataset.price);
        let priceNotVat = parseFloat(this.element.dataset.priceNotVat);
        let originalPrice = parseFloat(this.element.dataset.originalPrice);

        if(originalPrice > 0) {
            this.ref.priceOldBlock.style.display = 'block';
        } else {
            this.ref.priceOldBlock.style.display = 'none';
        }

        this.ref.price.textContent = parseFloat(count * priceNotVat).toLocaleString(undefined,{minimumFractionDigits: currencyRound, maximumFractionDigits: currencyRound});
        this.ref.priceOld.textContent = parseFloat(count * originalPrice).toLocaleString(undefined, {minimumFractionDigits: currencyRound, maximumFractionDigits: currencyRound});
        this.ref.priceVat.textContent = parseFloat(count * price).toLocaleString(undefined, {minimumFractionDigits: currencyRound, maximumFractionDigits: currencyRound});
    }

    connect() {

        let _self = this;
        if(typeof this.ref.select !== 'undefined') {
            let selectElem = this.ref.select;
            let totalPriceElem = this.ref.totalPrice;
            this.ref.select.addEventListener("change", function(e) {
                let price = selectElem.options[selectElem.selectedIndex].dataset.price;
                let originalPrice = selectElem.options[selectElem.selectedIndex].dataset.originalPrice;
                let priceNotVat = selectElem.options[selectElem.selectedIndex].dataset.priceNotVat;
                let variantId = selectElem.options[selectElem.selectedIndex].dataset.productId;

                _self.element.dataset.price = price;
                _self.element.dataset.priceNotVat = priceNotVat;
                _self.element.dataset.originalPrice = originalPrice;

                _self.updateCount();

                //let originalUri = _self.ref.basketButton.dataset.originalUrl;
                //_self.ref.basketButton.dataset.actionUrl = originalUri + '&variantId=' + variantId;

            }, false);
        }
    }

});