application.register("comp_products_detail", class extends Stimulus.Controller {
    connect() {
        let selector = this.element;

        selector.addEventListener("change", function(e) {
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(`[data-product-variant]`)) {
                    (function(event) {
                        let variantId;
                        if(event.target.nodeName === "SELECT") {
                            variantId = target.value;
                            let uri = target.querySelector('option[value="' + variantId + '"]').dataset.uri;
                            if(uri !== undefined) {
                                swup.loadPage({
                                    url: uri,
                                    method: 'GET',
                                    data: {},
                                    customTransition: ''
                                });
                            }
                        }
                    }).call(target, e);
                    break;
                }
            }
        }, false);
    }

    sharePage() {
        importScript(cdnjs.shareApiPolyfill,()=>{
            let keywords = document.head.querySelector('meta[name="keywords"]'),
                url = document.head.querySelector('meta[name="canonical"]'),
                description = document.head.querySelector('meta[name="description"]'),
                lang = document.documentElement.lang;

            if(["cs","de", "da", "en", "es", "fr", "ja", "ko", "nl", "pt", "ru", "sk", "tr", "zh"].indexOf(lang) === -1) {
                lang = "en";
            }

            navigator.share({
                    title: document.title,
                    text: (description !== null) ? description.getAttribute("content") : "",
                    url: (url !== null) ? url.getAttribute("content") : location.href,

                    // fbId: '123456789123456',
                    hashtags: (keywords !== null) ? keywords.getAttribute("content") : ""
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: false,
                    messenger: true,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: true,
                    telegram: false,
                    skype: false,
                    language: lang
                }
            )
                .then()
                .catch( error => console.log(error));
        });
    }
});