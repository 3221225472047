application.register("part_products", class extends Stimulus.Controller {
    get ref() {
        return {
            select: this.targets.find("select"),
            count: this.targets.find("count"),
            totalPrice: this.targets.find("totalPrice"),
            basketButton: this.targets.find("basketButton"),
        }
    }

    updateCount() {
        let count = parseInt(this.ref.count.value);
        let options = JSON.parse(this.ref.totalPrice.dataset.options);
        let itemPrice =  parseFloat(this.ref.totalPrice.dataset.price);

        this.ref.totalPrice.textContent = `(${parseFloat(count * itemPrice).toLocaleString(undefined, {style: "currency", "currency":options.currency,minimumFractionDigits: 2})})`;
    }

    connect() {

        let _self = this;
        if(typeof this.ref.select !== 'undefined') {
            let selectElem = this.ref.select;
            let totalPriceElem = this.ref.totalPrice;
            this.ref.select.addEventListener("change", function(e) {
                let price = selectElem.options[selectElem.selectedIndex].dataset.price;
                let variantId = selectElem.options[selectElem.selectedIndex].dataset.productId;
                totalPriceElem.dataset.price = price;
                _self.updateCount();

                //let originalUri = _self.ref.basketButton.dataset.originalUrl;
                //_self.ref.basketButton.dataset.actionUrl = originalUri + '&variantId=' + variantId;

            }, false);
        }
    }
});