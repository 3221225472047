let searchTimeout;

application.register("part_form_search", class extends Stimulus.Controller {
    get ref() {
        return {
            autocompleteArea: this.targets.find("autocompleteArea"),
        }
    }
    connect() {

    }
    closeAutocomplete() {
        let self = this;

        if(typeof self.ref.autocompleteArea !== "undefined") {
            if (self.ref.autocompleteArea.classList.contains("state--active")) {
                clearTimeout(searchTimeout);
                self.ref.autocompleteArea.classList.add("state--hiding");
                searchTimeout = setTimeout(function() {
                    self.ref.autocompleteArea.classList.remove("state--active");
                    self.ref.autocompleteArea.classList.remove("state--hiding");
                },300);
            }
        }
    }
    doSearch(event) {
        clearTimeout(searchTimeout);
        let self = this;
        let selector = this.element;

        let query = event.target.value;
        let isSearching = 0;

        importScript(cdnjs.jquery,()=>{
            let searchIndicator = $('.wrp_form_autocomplete').find('input[name="searchSearching"]');
            if($('.wrp_form_autocomplete').is('.state--active') && searchIndicator.length && searchIndicator.val() === '1') {
                isSearching = 1;
            }

            if(query.length > 2) {
                $.ajax({
                    url: event.target.getAttribute('data-ajax-link'),
                    data: {query: query, isSearching: isSearching},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload);
                });
            } else {
                if(typeof self.ref.autocompleteArea !== "undefined") {
                    self.ref.autocompleteArea.classList.remove("state--active");
                }
            }
        });
    }

    doSearchInitial(event) {
        let self = this;
        let query = event.target.value;

        if(query.length < 2) {
            importScript(cdnjs.jquery,()=>{
                $.ajax({
                    url: event.target.getAttribute('data-ajax-link-initial'),
                    data: {query: query},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload);
                    if(typeof self.ref.autocompleteArea !== "undefined") {
                        self.ref.autocompleteArea.classList.add("state--active");
                    }
                });
            });

            document.documentElement.addEventListener("click", function e(event) {
                if (!event.target.closest(".part_form_search")) {
                    self.closeAutocomplete();
                    document.documentElement.removeEventListener("click", e);
                }
            });
        }
    }
});