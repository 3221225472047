const LibSlider = function (element, options) {
    if(options === null) {
        options = {}
    }
    if (typeof options["destroyDevices"] !== "undefined" && window.innerWidth < 960) {
        return false;
    }
    importScript(cdnjs.flickity, ()=> {
        cssLoaded(()=> {

            let item_count = element.childElementCount;

            if (typeof options["groupCells"] !== "undefined") {
                if (window.innerWidth < 768) {
                    options["groupCells"] = "25%"
                }
            }

            let options_all = {
                groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                setGallerySize: true,
                autoPlay: options["autoplay"],
                pageDots: true,
                wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                contain: true,
                adaptiveHeight: false,
                pauseAutoPlayOnHover: true,
                prevNextButtons: true,
                percentPosition: false,
                on: {
                    ready: function () {
                        if (element.querySelectorAll(".flickity-button[disabled]").length === 2) {
                            element.classList.add("flickity-nav-disabled");
                        }
                    }
                }
            };

            let slider = new Flickity( element, options_all);

            element.classList.add("flickity-flex");

            slider.on("dragStart",()=>{
                [...slider.slider.childNodes].forEach(slide=>{
                    slide.style.pointerEvents = "none";
                })
            });

            slider.on("dragEnd",()=>{
                [...slider.slider.childNodes].forEach(slide=>{
                    slide.style.pointerEvents = null;
                })
            });

            function fn_arrows() {
                let slider_elm = element.querySelector(".flickity-slider");
                if(slider_elm !== null) {
                    let items_length =  slider_elm.childElementCount;
                    let items = slider_elm.children[0];
                    let items_width = items.offsetWidth + parseInt(getComputedStyle(items).marginLeft) + parseInt(getComputedStyle(items).marginRight);
                    let width = slider_elm.offsetWidth;

                    if (width >= (items_length * items_width)) {
                        [...element.querySelectorAll(".flickity-button")].forEach((elm)=>{elm.style.display = "none"});
                    } else {
                        [...element.querySelectorAll(".flickity-button")].forEach((elm)=>{elm.style.display = "block"});
                    }
                }
            }
            fn_arrows();

            window.addEventListener("resize",debounce(()=> {
                if (typeof options["destroyDevices"] === "undefined" && window.innerWidth >= 960) {
                    fn_arrows();
                    element.classList.remove("flickity-flex");
                    slider.resize();
                    element.classList.add("flickity-flex");
                }
            },250));

            if (typeof options["min"] !== "undefined") {
                if(typeof options["min"]["desktop"] !== "undefined") {
                    if(parseInt(options["min"]["desktop"]) > item_count) {
                        slider.destroy();
                    }
                }
            }

            if (typeof options["destroyDevices"] !== "undefined") {
                if (window.innerWidth < 960) {
                    slider.destroy();
                }

                const mediaQuery = window.matchMedia('screen and (min-width : 60em)');
                mediaQuery.onchange = e => {
                    if (window.innerWidth > 960) {
                        slider = new Flickity(options_all);
                    } else {
                        slider.destroy();
                    }
                }
            }
        });
    });
};