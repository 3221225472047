application.register("layout_header", class extends Stimulus.Controller {
    get offsetTop() {
        if (this._offsetTop === undefined) {
            this._offsetTop = 0
        }
        return this._offsetTop
    }
    get ref() {
        return {
            searchArea: this.targets.find("header-search"),
            headerBanner: this.targets.find("header-banner"),
            basket: this.targets.find("basket"),
            basketInner: this.targets.find("basketInner")
        }
    }
    connect() {
        if (typeof this.ref.headerBanner !== "undefined") {
            if (localStorage.getItem("bannerClosed") !== `version-${this.ref.headerBanner.dataset.version}`) {
                this.element.classList.add("has--banner");
                this.ref.headerBanner.removeAttribute("hidden");
            }
        }
        let self = this;
        let currentScrollY = 0;
        let latestKnownScrollY = 0;
        let ticking = false;

        let header = this.element;
        let footer = document.getElementById("layout_footer");

        function setHeaderState() {
            if (currentScrollY > self._offsetTop) {
                header.classList.add("headroom--not-top");
                if (currentScrollY > latestKnownScrollY){
                    header.classList.remove("headroom--pinned");
                    header.classList.add("headroom--unpinned");
                } else {
                    header.classList.remove("headroom--unpinned");
                    header.classList.add("headroom--pinned");
                }
            } else {
                header.classList.remove("headroom--not-top", "headroom--pinned", "headroom--unpinned");
            }
            latestKnownScrollY = currentScrollY;
        }

        function update() {
            setHeaderState();
            ticking = false;
        }

        function onScroll() {
            if(!ticking) {
                requestAnimationFrame(update);
                ticking = true;
            }
        }

        let thresholds = [];
        for (let i=0; i<=1.0; i+= 0.01) {
            thresholds.push(i);
        }

        let observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: thresholds
        };
        let visibleHeader, visibleFooter;

        new IntersectionObserver(intersectionCallback, observerOptions).observe(header);
        new IntersectionObserver(intersectionCallback, observerOptions).observe(footer);

        function intersectionCallback(entries) {
            entries.forEach((entry) => {
                if(entry.target === header) {
                    visibleHeader = Math.ceil(entry.target.getBoundingClientRect().height * entry.intersectionRatio);
                }
                if(entry.target === footer) {
                    visibleFooter = Math.ceil(entry.target.getBoundingClientRect().height * entry.intersectionRatio);
                }
            });

            if(window.innerWidth >= 960) {
                self.ref.basketInner.style.height = (window.innerHeight - visibleHeader - visibleFooter) + "px";
            }
            else {
                self.ref.basketInner.style.height = null;
            }
        }

        cssLoaded(()=>{
            visibleHeader= Math.ceil(header.clientHeight);
            visibleFooter= 0;
            if(window.innerWidth >= 960) {
                self.ref.basketInner.style.height = (window.innerHeight - visibleHeader - visibleFooter) + "px";
            }
            else {
                self.ref.basketInner.style.height = null;
            }
        });

        document.addEventListener('swup:animationInDone', () => {
            visibleHeader= Math.ceil(header.clientHeight);
            visibleFooter= 0;
            if(window.innerWidth >= 960) {
                self.ref.basketInner.style.height = (window.innerHeight - visibleHeader - visibleFooter) + "px";
            }
            else {
                self.ref.basketInner.style.height = null;
            }
        });



        cssLoaded(function() {
            self._offsetTop = document.getElementById("layout_nav_swup").offsetTop;
            setTimeout(() => {
                window.addEventListener('scroll', ()=>{
                    currentScrollY = window.scrollY;
                    onScroll();
                }, false);
            }, 1000);
        });


        if (document.querySelector("#layout_nav") === null) {
            this.element.insertAdjacentHTML("afterend", `<div id="layout_nav" class="lib--drawer" data-target="lib-drawer.nav" data-action="scroll->lib-drawer#scroll"><div class="wrp_nav"><div class="wrp_nav_head"></div><div class="wrp_nav_body"></div></div></div>`);

            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").init();

            let layout_nav = document.querySelector("#layout_nav"),
                logo = this.element.querySelector(".elm_header_logo").outerHTML,
                navs = this.element.querySelectorAll(".elm_header_links"),
                mainCatagories = this.element.querySelector("#layout_nav_swup").querySelector(".elm_nav_inner");

            layout_nav.querySelector(".wrp_nav_head").insertAdjacentHTML("beforeend", logo);
            // layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", mainCatagories.outerHTML);
            [...navs].forEach((nav)=> {
                layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", nav.outerHTML);
            })
        }
    }

    bannerClose() {
        let self = this;
        self.element.classList.remove("has--banner");
        localStorage.setItem("bannerClosed", `version-${this.ref.headerBanner.dataset.version}`);
        self.ref.headerBanner.parentNode.removeChild(this.ref.headerBanner);
        self._offsetTop = document.getElementById("layout_nav_swup").offsetTop;
    }

    searchOpen() {
        this.ref.searchArea.classList.add("state--active");
        this.ref.searchArea.querySelector("input").focus();
        document.body.classList.add("body--no-scroll");
    }

    searchClose(event) {
        event.preventDefault();
        this.ref.searchArea.classList.remove("state--active");
        document.body.classList.remove("body--no-scroll");
    }

    toggleBasket(e) {
        let button = e.currentTarget;

        if(this.ref.basketInner.classList.contains("state--active")) {
            this.ref.basketInner.classList.remove("state--active");
            document.body.classList.remove("body--no-scroll");
            document.body.classList.remove("is--basket-active");
        }
        else {
            this.ref.basketInner.classList.add("state--active");
            document.body.classList.add("is--basket-active");

            if(window.innerWidth < 768) {
                document.body.classList.add("body--no-scroll")
            }
        }
    }
});

window.lui_nav_categories = {};

application.register("layout_nav_swup", class extends Stimulus.Controller {
    connect() {

        let selector = this.element;
        let categories = this.targets.find("categories");

        let timer_in;
        let timer_out;
        let currentUrl;
        let currentUrlActive;

        selector.removeEventListener("mouseenter", null);
        selector.removeEventListener("mouseleave", null);

        selector.addEventListener("mouseenter", function(e) {
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(`[data-hover="categories"]`)) {
                    (function() {
                        let elm = target;
                        let url = elm.dataset.url;
                        let delay_in = 0;
                        [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link)=>{
                            if(link.getAttribute("data-url")!==currentUrlActive) {
                                link.classList.remove("state--hover");
                            }
                        })
                        clearTimeout(timer_in);
                        clearTimeout(timer_out);

                        currentUrl = url;
                        if (typeof lui_nav_categories[url] === "undefined") {
                            fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                                return response.json();
                            }).then((data) => {
                                lui_nav_categories[url] = data.snippet;
                                if (currentUrl === url) {
                                    if(new DOMParser().parseFromString(data.snippet, 'text/html').body.firstChild.querySelector(".wrp_part_body").childElementCount > 0) {
                                        if(categories.classList.contains("state--active")) {
                                            delay_in = 300
                                        }
                                        timer_in = setTimeout(function(){
                                            if (!elm.classList.contains("state--hover") && !elm.classList.contains("state--active")) {
                                                [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                                                    link.classList.remove("state--hover");
                                                })
                                                elm.classList.add("state--hover");
                                                categories.innerHTML = "";
                                                categories.insertAdjacentHTML("beforeend", lui_nav_categories[url]);
                                                categories.classList.add("state--active");
                                                currentUrlActive = url;
                                            }
                                        }, delay_in);
                                    } else {
                                        timer_out = setTimeout(function() {
                                            [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                                                link.classList.remove("state--hover");
                                            })
                                            categories.classList.add("state--hiding");
                                            setTimeout(function() {
                                                categories.classList.remove("state--active", "state--hiding");
                                            },300);
                                        },500);
                                    }
                                }
                            });
                        } else {
                            if(new DOMParser().parseFromString(lui_nav_categories[url], 'text/html').body.firstChild.querySelector(".wrp_part_body").childElementCount > 0) {
                                if(categories.classList.contains("state--active")) {
                                    delay_in = 300
                                }
                                timer_in = setTimeout(function(){
                                    if (!elm.classList.contains("state--hover") && !elm.classList.contains("state--active")) {
                                        [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                                            link.classList.remove("state--hover");
                                        })
                                        elm.classList.add("state--hover");
                                        categories.innerHTML = "";
                                        categories.insertAdjacentHTML("beforeend", lui_nav_categories[url]);
                                        categories.classList.add("state--active");
                                        currentUrlActive = url;
                                    }
                                }, delay_in);
                            } else {
                                timer_out = setTimeout(function() {
                                    [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                                        link.classList.remove("state--hover");
                                    })
                                    categories.classList.add("state--hiding");
                                    setTimeout(function() {
                                        categories.classList.remove("state--active", "state--hiding");
                                    },300);
                                },500);
                            }
                        }

                    }).call(target, e);
                    break;
                }
            }
        }, true);

        selector.addEventListener("mouseleave", function(e) {
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(`[data-hover="categories"]`)) {
                    (function() {
                        [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link)=>{
                            if(link.getAttribute("data-url")!==currentUrlActive) {
                                link.classList.remove("state--hover");
                            }
                        })
                        clearTimeout(timer_in);
                        clearTimeout(timer_out);

                        currentUrl = null;
                        if (categories.classList.contains("state--active")) {
                            timer_out = setTimeout(function() {
                                [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                                    link.classList.remove("state--hover");
                                })
                                categories.classList.add("state--hiding");
                                setTimeout(function() {
                                    categories.classList.remove("state--active", "state--hiding");
                                },300);
                            },500);
                        }
                    }).call(target, e);
                    break;
                }
            }
        }, true);

        categories.removeEventListener("mouseenter", null);
        categories.removeEventListener("mouseleave", null);

        categories.addEventListener("mouseenter", function(e) {
            clearTimeout(timer_in);
            clearTimeout(timer_out);
            if (typeof currentUrlActive !== "undefined") {
                selector.querySelector(`a[data-url="${currentUrlActive}"]`).classList.add("state--hover");
            }
        }, true);
        categories.addEventListener("mouseleave", function(e) {
            clearTimeout(timer_in);
            clearTimeout(timer_out);
            timer_out = setTimeout(function() {
                [...selector.querySelectorAll(`[data-hover="categories"]`)].forEach((link) => {
                    link.classList.remove("state--hover");
                })
                categories.classList.add("state--hiding");
                setTimeout(function() {
                    categories.classList.remove("state--active", "state--hiding");
                },300);
            },500);
        }, true);
    }
});